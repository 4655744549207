import FDVue from "@fd/lib/vue";
import { mapMutations } from "vuex";
import rules from "@fd/lib/vue/rules";
import {
  ContractorWithTags,
  Discipline,
  ProjectLocation,
  ScaffoldRequestTypes,
  Tag,
  WorkOrderForAllWorkOrdersList,
  WorkOrderStatusDetails,
  WorkOrderStatuses,
  contractorService,
  disciplineService,
  personService,
  projectLocationService,
  workOrderService,
  workOrderStatusHelper
} from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import {
  FilteringRequestorContext,
  FilteringContext,
  FilteringAssignedContext,
  FilteringUnassignedContext
} from "../dataMixins/workOrderList";
import { DateRangePreset } from "@fd/lib/vue/components/DateRangePicker.vue";
import archivedDataList from "../dataMixins/archivedDataList";
import userAccess from "../dataMixins/userAccess";
import { GetPersonName, PersonWithName, SortItemsWithName } from "../utils/person";
import { filterByTags } from "../services/taggableItems";
import { valueInArray } from "@fd/lib/client-util/array";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import {
  contractorDataStore,
  disciplineDataStore,
  personBySecurityIDDataStore,
  personDataStore,
  projectLocationDataStore
} from "../store";

export default FDVue.extend({
  name: "fd-all-work-orders",

  mixins: [userAccess, rules, archivedDataList],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-work-order-details": () => import("./components/WorkOrderDetailsForm.vue"),
    "fd-scaffold-number-with-badges": () => import("./components/ScaffoldNumberWithBadges.vue"),
    "sp-inline-scope-icons": () => import("./components/SP.InlineScopeIcons.vue")
  },

  data: function() {
    return {
      reloadTimer: null as NodeJS.Timeout | null,
      dataReloadMinutes: 5,

      allWorkOrders: [] as WorkOrderForAllWorkOrdersList[],

      minDate: undefined as Date | undefined,
      maxDate: undefined as Date | undefined,

      allRequestTypes: (Object.keys(ScaffoldRequestTypes)
        .filter(x => !isNaN(Number(x)))
        .map(x => Number(x)) as number[]).map(x => {
        return {
          value: x,
          text: this.$t(`scaffold-requests.types.${x}`)
        };
      }),

      allWorkOrderStatuses: [] as WorkOrderStatusDetails[],

      allContractors: [] as ContractorWithTags[],
      allCoordinators: [] as PersonWithName[],
      allGeneralForemen: [] as PersonWithName[],
      allForemen: [] as PersonWithName[],
      allDisciplines: [] as Discipline[],
      currentUserDisciplines: [] as Discipline[],
      allAreas: [] as ProjectLocation[],
      allSubAreas: [] as ProjectLocation[],

      itemsPerPage: 25,
      itemsPerPageOptions: [5, 10, 15, 25, 50, -1]
    };
  },

  computed: {
    canFilterRequestType(): boolean {
      return (
        !!this.curUserAccess.canViewMaintenanceJobs ||
        !!this.curUserAccess.canViewPaintJobs ||
        !!this.curUserAccess.canViewInsulationJobs
      );
    },
    expanderColSpan(): number {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 14;
      } else if (this.$vuetify.breakpoint.xsOnly) {
        return 5;
      } else if (this.$vuetify.breakpoint.smOnly) {
        return 10;
      } else {
        return 14;
      }

      return 14;
    },
    dateRangePresetOptions(): DateRangePreset[] {
      return [
        {
          fromDate: DateUtil.addDaysToDate(null, 0),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "today",
          labelKey: "fd-date-range-picker.preset-today-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -6),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-week",
          labelKey: "fd-date-range-picker.preset-previous-week-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addDaysToDate(null, -13),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-weeks",
          labelKey: "fd-date-range-picker.preset-previous-two-weeks-label"
        } as DateRangePreset,
        {
          fromDate: DateUtil.addMonthsToDate(null, -2),
          toDate: DateUtil.addDaysToDate(null, 0),
          key: "previous-two-months",
          labelKey: "fd-date-range-picker.preset-previous-two-months-label"
        } as DateRangePreset
      ];
    },
    baseFilteredWorkOrders(): WorkOrderForAllWorkOrdersList[] {
      var filteredWorkOrders = this.allWorkOrders;
      if (this.requestorFilterIsMine) {
        // returns requests either submitted by the current user, or on the current user's behalf
        filteredWorkOrders = filteredWorkOrders.filter(
          x =>
            (!!x.coordinatorID && x.coordinatorID == this.curUserID) ||
            (!!x.generalForemanID && x.generalForemanID == this.curUserID) ||
            (!!x.foremanID && x.foremanID == this.curUserID)
        );
      }
      if (this.selectedRequestTypes.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.scaffoldRequestType, this.selectedRequestTypes)
        );
      }
      if (this.selectedStatuses.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.workOrderStatus, this.selectedStatuses)
        );
      }
      if (this.selectedContractors.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.assignedContractorID, this.selectedContractors)
        );
      }
      if (this.selectedDisciplines.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.disciplineID, this.selectedDisciplines)
        );
      }
      if (this.selectedForemanIDs.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.foremanID, this.selectedForemanIDs)
        );
      }
      if (this.selectedGeneralForemanIDs.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.generalForemanID, this.selectedGeneralForemanIDs)
        );
      }
      if (this.selectedAreas.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.areaID, this.selectedAreas)
        );
      }
      if (this.selectedSubAreas.length) {
        filteredWorkOrders = filteredWorkOrders.filter(x =>
          valueInArray(x.subAreaID, this.selectedSubAreas)
        );
      }
      return filterByTags(this.tagsSelectedForFiltering, filteredWorkOrders);
    },
    filteredWorkOrders(): WorkOrderForAllWorkOrdersList[] {
      let filteredWorkOrders = this.baseFilteredWorkOrders;
      if (!this.showScaffoldRequests) {
        filteredWorkOrders = filteredWorkOrders.filter(
          wo =>
            wo.scaffoldRequestType != ScaffoldRequestTypes.Erect &&
            wo.scaffoldRequestType != ScaffoldRequestTypes.Modify &&
            wo.scaffoldRequestType != ScaffoldRequestTypes.Dismantle
        );
      }
      if (!this.showMaintenanceRequests) {
        filteredWorkOrders = filteredWorkOrders.filter(
          wo => wo.scaffoldRequestType != ScaffoldRequestTypes.Maintenance
        );
      }
      if (!this.showPaintRequests) {
        filteredWorkOrders = filteredWorkOrders.filter(
          wo => wo.scaffoldRequestType != ScaffoldRequestTypes.Paint
        );
      }
      if (!this.showInsulationRequests) {
        filteredWorkOrders = filteredWorkOrders.filter(
          wo => wo.scaffoldRequestType != ScaffoldRequestTypes.Insulation
        );
      }
      if (!this.includeUnassigned) {
        // filter out requests which are not fully assigned and planned
        // showWorkOrderOnToDo is used since this state is dependant on the work order's status
        filteredWorkOrders = filteredWorkOrders.filter(
          x => !!x.foremanID && !!x.generalForemanID && !!this.showWorkOrderOnToDo(x)
        );
      }
      if (!this.includeAssigned) {
        // filter out requests which are fully assigned and planned
        // showWorkOrderOnToDo is used since this state is dependant on the work order's status
        filteredWorkOrders = filteredWorkOrders.filter(
          x => !x.foremanID || !x.generalForemanID || !this.showWorkOrderOnToDo(x)
        );
      }
      return filteredWorkOrders;
    },
    filteringContext: {
      get(): FilteringContext {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
      },
      set(val: FilteringContext) {
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", val);
      }
    },
    requestorFilter: {
      get(): FilteringRequestorContext {
        var context: FilteringContext = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
        return context.requestorFilter ?? "all";
      },
      set(val: FilteringRequestorContext) {
        var context: FilteringContext =
          this.$store.state.filters.find(
            (x: any) => x.context == this.$store.state.filteringContext
          )!.contextForFiltering ?? {};
        context.requestorFilter = val;
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", context);
      }
    },
    requestorFilterIsMine: {
      get(): boolean {
        return this.requestorFilter == "mine";
      },
      set(val: boolean) {
        this.requestorFilter = val ? "mine" : "all";
      }
    },
    showScaffoldRequests: {
      get(): boolean {
        return this.filteringContext.showScaffoldRequests;
      },
      set(val: boolean) {
        let filteringContext = this.filteringContext;
        filteringContext.showScaffoldRequests = val;
        this.filteringContext = filteringContext;
      }
    },
    showMaintenanceRequests: {
      get(): boolean {
        return this.filteringContext.showMaintenanceRequests;
      },
      set(val: boolean) {
        let filteringContext = this.filteringContext;
        filteringContext.showMaintenanceRequests = val;
        this.filteringContext = filteringContext;
      }
    },
    showPaintRequests: {
      get(): boolean {
        return this.filteringContext.showPaintRequests;
      },
      set(val: boolean) {
        let filteringContext = this.filteringContext;
        filteringContext.showPaintRequests = val;
        this.filteringContext = filteringContext;
      }
    },
    showInsulationRequests: {
      get(): boolean {
        return this.filteringContext.showInsulationRequests;
      },
      set(val: boolean) {
        let filteringContext = this.filteringContext;
        filteringContext.showInsulationRequests = val;
        this.filteringContext = filteringContext;
      }
    },

    unassignedFilter: {
      get(): FilteringUnassignedContext {
        var context: FilteringContext = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
        return context.unassignedFilter;
      },
      set(val: FilteringUnassignedContext) {
        var context: FilteringContext =
          this.$store.state.filters.find(
            (x: any) => x.context == this.$store.state.filteringContext
          )!.contextForFiltering ?? {};
        context.unassignedFilter = val;
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", context);
      }
    },
    includeUnassigned: {
      get(): boolean {
        return this.unassignedFilter;
      },
      set(val: boolean) {
        this.unassignedFilter = val;
      }
    },

    assignedFilter: {
      get(): FilteringAssignedContext {
        var context: FilteringContext = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contextForFiltering;
        return context.assignedFilter;
      },
      set(val: FilteringAssignedContext) {
        var context: FilteringContext =
          this.$store.state.filters.find(
            (x: any) => x.context == this.$store.state.filteringContext
          )!.contextForFiltering ?? {};
        context.assignedFilter = val;
        this.$store.commit("SET_CONTEXT_FOR_FILTERING", context);
      }
    },
    includeAssigned: {
      get(): boolean {
        return this.assignedFilter;
      },
      set(val: boolean) {
        this.assignedFilter = val;
      }
    },

    canViewContractorFilter(): boolean {
      return this.curUserCanViewAllContractors || this.curUserContractorIDs.length != 1;
    },

    canViewDisciplinesFilter(): boolean {
      return (
        !!this.currentUserDisciplines &&
        (this.currentUserDisciplines.length != 1 || this.selectableDisciplines.length > 1)
      );
    },

    tagsInUse(): Tag[] {
      return this.$store.getters.getSortedInUseTags(this.allWorkOrders);
    },

    tagsSelectedForFiltering: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.tagsForFiltering;
      },
      set(val) {
        this.$store.commit("SET_TAGS_FOR_FILTERING", val);
      }
    },

    selectableDisciplines(): Discipline[] {
      let selectableDisciplineIDs = this.allWorkOrders
        .filter(x => !!x.disciplineID)
        .map(x => x.disciplineID!);
      selectableDisciplineIDs = [...new Set(selectableDisciplineIDs)];
      return this.allDisciplines.filter(x => selectableDisciplineIDs.includes(x.id!));
    },

    selectedDisciplines: {
      get(): string[] {
        var selectedDisciplines = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.disciplinesForFiltering as string[];
        selectedDisciplines = selectedDisciplines.filter(x =>
          valueInArray(
            x,
            this.selectableDisciplines.map(x => x.id!)
          )
        );
        return selectedDisciplines;
      },
      set(val: string[]) {
        this.$store.commit("SET_DISCIPLINES_FOR_FILTERING", val);
      }
    },

    selectableGeneralForemen(): PersonWithName[] {
      let selectableGeneralForemanIDs = this.allWorkOrders
        .filter(x => !!x.generalForemanID)
        .map(x => x.generalForemanID!);
      selectableGeneralForemanIDs = [...new Set(selectableGeneralForemanIDs)];

      return this.allGeneralForemen.filter(x => valueInArray(x.id, selectableGeneralForemanIDs));
    },

    selectedGeneralForemanIDs: {
      get(): string[] {
        var selectedGeneralForemanIDs = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.generalForemanIDsForFiltering as string[];
        selectedGeneralForemanIDs = selectedGeneralForemanIDs.filter(x =>
          valueInArray(
            x,
            this.selectableGeneralForemen.map(x => x.id!)
          )
        );
        return selectedGeneralForemanIDs;
      },
      set(val: string[]) {
        this.$store.commit("SET_GENERAL_FOREMAN_IDS_FOR_FILTERING", val);
      }
    },

    selectableForemen(): PersonWithName[] {
      let selectableForemanIDs = this.allWorkOrders
        .filter(x => !!x.foremanID)
        .map(x => x.foremanID!);
      selectableForemanIDs = [...new Set(selectableForemanIDs)];

      return this.allForemen.filter(x => valueInArray(x.id, selectableForemanIDs));
    },

    selectedForemanIDs: {
      get(): string[] {
        var selectedForemanIDs = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.foremanIDsForFiltering as string[];
        selectedForemanIDs = selectedForemanIDs.filter(x =>
          valueInArray(
            x,
            this.selectableForemen.map(x => x.id!)
          )
        );
        return selectedForemanIDs;
      },
      set(val: string[]) {
        this.$store.commit("SET_FOREMAN_IDS_FOR_FILTERING", val);
      }
    },

    selectedStatuses: {
      get(): number[] {
        var selectedStatuses = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.statusesForFiltering as number[];
        return selectedStatuses;
      },
      set(val: number[]) {
        this.$store.commit("SET_STATUSES_FOR_FILTERING", val);
      }
    },

    selectedRequestTypes: {
      get(): number[] {
        var selectedRequestTypes = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.requestTypesForFiltering as number[];
        selectedRequestTypes = selectedRequestTypes.filter(x =>
          valueInArray(
            x,
            this.allRequestTypes.map(x => x.value!)
          )
        );
        return selectedRequestTypes;
      },
      set(val: number[]) {
        this.$store.commit("SET_REQUEST_TYPES_FOR_FILTERING", val);
      }
    },

    selectableContractors(): ContractorWithTags[] {
      let selectableContractorIDs = this.allWorkOrders.map(x => x.assignedContractorID);
      selectableContractorIDs = [...new Set(selectableContractorIDs)];
      return this.allContractors.filter(x => selectableContractorIDs.includes(x.id!));
    },

    selectedContractors: {
      get(): string[] {
        var selectedContractors = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.contractorsForFiltering as string[];
        selectedContractors = selectedContractors.filter(x =>
          valueInArray(
            x,
            this.selectableContractors.map(x => x.id!)
          )
        );
        return selectedContractors;
      },
      set(val: string[]) {
        this.$store.commit("SET_CONTRACTORS_FOR_FILTERING", val);
      }
    },

    selectableAreas(): ProjectLocation[] {
      let selectableAreaIDs = this.allWorkOrders.filter(x => !!x.areaID).map(x => x.areaID!);
      selectableAreaIDs = [...new Set(selectableAreaIDs)];
      return this.allAreas.filter(x => valueInArray(x.id, selectableAreaIDs));
    },

    selectedAreas: {
      get(): string[] {
        var selectedAreas = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.areasForFiltering as string[];
        selectedAreas = selectedAreas.filter(x =>
          valueInArray(
            x,
            this.selectableAreas.map(x => x.id!)
          )
        );
        return selectedAreas;
      },
      set(val: string[]) {
        this.$store.commit("SET_AREAS_FOR_FILTERING", val);
      }
    },

    selectableSubAreas(): ProjectLocation[] {
      let selectableSubAreaIDs = this.allWorkOrders
        .filter(x => !!x.subAreaID)
        .map(x => x.subAreaID!);
      selectableSubAreaIDs = [...new Set(selectableSubAreaIDs)];

      let selectedAreaIDs = this.selectedAreas;
      return this.allSubAreas.filter(
        x =>
          valueInArray(x.parentLocationID, selectedAreaIDs) &&
          valueInArray(x.id, selectableSubAreaIDs)
      );
    },

    selectedSubAreas: {
      get(): string[] {
        var selectedSubAreas = this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.subAreasForFiltering as string[];
        selectedSubAreas = selectedSubAreas.filter(x =>
          valueInArray(
            x,
            this.selectableSubAreas.map(x => x.id!)
          )
        );
        return selectedSubAreas;
      },
      set(val: string[]) {
        this.$store.commit("SET_SUB_AREAS_FOR_FILTERING", val);
      }
    },

    tablesearch: {
      get() {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    },

    filterStatusesWithCounts(): { name: string; count: number }[] {
      // TODO: This is a hacky hodge-podge way to get a list of statuses requiring string comparisons, we should normalize this somehow
      return this.allWorkOrderStatuses.map(x => {
        return {
          value: x.value,
          name: x.displayName,
          count: this.allWorkOrders.filter(wo => wo.workOrderStatus == x.value).length
        };
      });
    }
  },

  watch: {},

  methods: {
    workOrderIsOnHold(workOrder: WorkOrderForAllWorkOrdersList): boolean {
      return workOrder.workOrderStatus == WorkOrderStatuses.OnHold;
    },

    workOrderIsCancelled(workOrder: WorkOrderForAllWorkOrdersList): boolean {
      return workOrder.workOrderStatus == WorkOrderStatuses.Cancelled;
    },

    workOrderIsInScheduling(workOrder: WorkOrderForAllWorkOrdersList): boolean {
      return workOrder.workOrderStatus == WorkOrderStatuses.InScheduling;
    },

    workOrderIsStarted(workOrder: WorkOrderForAllWorkOrdersList): boolean {
      return workOrder.workOrderStatus == WorkOrderStatuses.Started;
    },

    workOrderNeedsWalkdown(workOrder: WorkOrderForAllWorkOrdersList): boolean {
      return (
        workOrder.workOrderStatus == WorkOrderStatuses.Walkdown ||
        workOrder.workOrderStatus == WorkOrderStatuses.Estimated
      );
    },
    showWorkOrderOnToDo(workOrder: WorkOrderForAllWorkOrdersList): boolean {
      let val = this.workOrderNeedsWalkdown(workOrder)
        ? !!workOrder.plannedWalkdownStartDate
        : !!workOrder.plannedWorkStartDate;
      return val;
    },
    customSort(
      items: WorkOrderForAllWorkOrdersList[],
      sortBy: string[],
      sortDesc: boolean[],
      locale: string
    ) {
      let desc = sortDesc[0] == true;
      let propName = sortBy[0];
      // Examine the property trying to be sorted, and switch it to the ACTUAL property name of the object
      // This prop name is the name of the item in the table, and therefore sometimes won't match the object property.
      if (propName === "editablePriority") {
        propName = "priority";
      } else if (propName === "editableProgress") {
        propName = "progress";
      } else if (propName === "formattedRequiredDate") {
        propName = "requiredDate";
      } else if (propName === "generalForeman") {
        propName = "generalForemanName";
      } else if (propName === "foreman") {
        propName = "foremanName";
      } else if (propName === "workOrderStatusName") {
        propName = "workOrderStatus";
      }

      items.sort((a: WorkOrderForAllWorkOrdersList, b: WorkOrderForAllWorkOrdersList) => {
        let val1 = (a as any)[propName];
        let val2 = (b as any)[propName];
        if (propName == "editablePriority" || propName == "priority") {
          if (a.isUrgent) val1 = 0;
          if (b.isUrgent) val2 = 0;
        } else if (propName == "todo") {
          // since the "todo" column uses a different value for each row, the prop name for each item being compared may be different.
          if (this.workOrderNeedsWalkdown(a)) {
            val1 = !!a.plannedWalkdownStartDate;
          } else {
            val1 = !!a.plannedWorkStartDate;
          }

          if (this.workOrderNeedsWalkdown(b)) {
            val2 = !!a.plannedWalkdownStartDate;
          } else {
            val2 = !!a.plannedWorkStartDate;
          }
        }
        if (val1 < val2) {
          return desc ? 1 : -1;
        } else if (val1 > val2) {
          return desc == true ? -1 : 1;
        } else {
          return 0;
        }
      });

      return items;
    },
    /*** GLOBAL ***/
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    async loadData() {
      let allWorkOrders = (
        await workOrderService.getAllWorkOrders(
          this.showArchived,
          this.showArchivedFromDate,
          this.showArchivedToDate
        )
      ).map(x => ({
        ...x,
        isLoading: false,
        isModifyRequest: x.scaffoldRequestType == ScaffoldRequestTypes.Modify,
        approvalComments: x.walkdown.approvalComments,
        requestType: x.scaffoldRequestType,

        coordinatorName: personDataStore.lookupCaption(x.coordinatorID ?? null) ?? "",
        generalForemanName: personDataStore.lookupCaption(x.generalForemanID ?? null) ?? "",
        foremanName: personDataStore.lookupCaption(x.foremanID ?? null) ?? "",
        assignedContractorName:
          contractorDataStore.lookupCaption(x.assignedContractorID ?? null) ?? "",
        requestingEmployeeName: personDataStore.lookupCaption(x.requestingEmployeeID ?? null) ?? "",
        requestingContractorName:
          contractorDataStore.lookupCaption(x.requestingContractorID ?? null) ?? "",
        requestSubmitterName: personBySecurityIDDataStore.lookupCaption(x.requestSubmittedBy) ?? "",
        areaName: projectLocationDataStore.lookupCaption(x.areaID ?? null) ?? "",
        subAreaName: projectLocationDataStore.lookupCaption(x.subAreaID ?? null) ?? "",
        disciplineName: disciplineDataStore.lookupCaption(x.disciplineID ?? null) ?? "",
        lastStatusChangedBy:
          personBySecurityIDDataStore.lookupCaption(x.lastStatusChangedByID ?? null) ?? "",
        lastUrgentDetailChangedBy:
          personBySecurityIDDataStore.lookupCaption(x.lastUrgentDetailChangedByID ?? null) ?? "",
        lastUrgentValueChangedBy:
          personBySecurityIDDataStore.lookupCaption(x.lastUrgentValueChangedByID ?? null) ?? ""
      }));
      this.allWorkOrders = allWorkOrders;
    },
    fromDateChanged(val: Date) {
      this.maxDate = DateUtil.addMonthsToDate(val, 2);
      let now = new Date();
      if (this.maxDate.getTime() > now.getTime()) this.maxDate = now;
      this.showArchivedMinDate;
    },
    toDateChanged(val: Date) {
      this.minDate = DateUtil.addMonthsToDate(val, -2);
    },
    async reloadTableData() {
      this.inlineMessage.message = "";
      this.processing = true;
      try {
        await this.loadData();
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // DOES NOT manage processing or error message logic
    async loadDisciplines(): Promise<void> {
      let disciplines = await disciplineService.getAll(false, null, null);
      this.allDisciplines = SortItemsWithName(disciplines);
    },

    // DOES NOT manage processing or error message logic
    async loadCurrentUserDisciplines(): Promise<void> {
      let disciplines = await disciplineService.getByPersonID(this.curUserID);
      this.currentUserDisciplines = disciplines;
    },

    // DOES NOT manage processing or error message logic
    async loadAreas(): Promise<void> {
      let areas = await projectLocationService.getVisibleAreas();
      this.allAreas = SortItemsWithName(areas);
    },

    // DOES NOT manage processing or error message logic
    async loadSubAreas(): Promise<void> {
      let subAreas = await projectLocationService.getVisibleSubAreas();
      this.allSubAreas = SortItemsWithName(subAreas);
    },

    // DOES NOT manage processing or error message logic
    async loadContractors(): Promise<void> {
      this.allContractors = SortItemsWithName(await contractorService.getAll(false, null, null));
    },

    // DOES NOT manage processing or error message logic
    async loadCoordinators(): Promise<void> {
      let coordinators = await personService.getAllCoordinators();
      this.allCoordinators = SortItemsWithName(
        coordinators.map(x => {
          return {
            ...x,
            name: GetPersonName(x)
          };
        })
      );
    },

    // DOES NOT manage processing or error message logic
    async loadGeneralForemen(): Promise<void> {
      let generalForemen = await personService.getAllGeneralForemen();
      this.allGeneralForemen = SortItemsWithName(
        generalForemen.map(x => {
          return {
            ...x,
            name: GetPersonName(x)
          };
        })
      );
    },

    // DOES NOT manage processing or error message logic
    async loadForemen(): Promise<void> {
      let foremen = await personService.getAllForemen();
      this.allForemen = SortItemsWithName(
        foremen.map(x => {
          return {
            ...x,
            name: GetPersonName(x)
          };
        })
      );
    }
  },

  created: async function() {
    var requestorFilter: FilteringRequestorContext = !!this.curUserAccess.homeContractorID
      ? "mine"
      : "all";
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    let toDate = DateUtil.addDaysToDate(null, 0);
    this.setFilteringContext({
      context: "history",
      parentalContext: null,
      showArchivedForFiltering: false,
      showArchivedForFilteringFromDate: DateUtil.addMonthsToDate(toDate, -2),
      showArchivedForFilteringToDate: toDate,
      searchStringForFiltering: "",
      tagsForFiltering: [],
      requestTypesForFiltering: [],
      statusesForFiltering: [],
      contractorsForFiltering: [],
      disciplinesForFiltering: [],
      foremanIDsForFiltering: [],
      generalForemanIDsForFiltering: [],
      areasForFiltering: [],
      subAreasForFiltering: [],
      contextForFiltering: {
        requestorFilter: requestorFilter,
        assignedFilter: true,
        unassignedFilter: true,
        showScaffoldRequests: true,
        showMaintenanceRequests: this.curUserAccess.canViewMaintenanceJobs,
        showPaintRequests: this.curUserAccess.canViewPaintJobs,
        showInsulationRequests: this.curUserAccess.canViewInsulationJobs
      } as FilteringContext
    });

    this.notifyNewBreadcrumb({
      text: this.$t("all-work-orders.list-title"),
      to: "/allworkorders",
      resetHistory: true
    });

    this.processing = true;
    try {
      await Promise.all([
        this.loadDisciplines(),
        this.loadCurrentUserDisciplines(),
        this.loadAreas(),
        this.loadSubAreas(),
        this.loadCoordinators(),
        this.loadGeneralForemen(),
        this.loadForemen(),
        this.loadContractors()
      ]);
      this.processing = true;
      this.allWorkOrderStatuses = await workOrderStatusHelper.getAllWorkOrderStatuses();
      await this.reloadTableData();

      // double check selectedRequestor filter based on table results
      var myWorkOrders = this.allWorkOrders.filter(
        x =>
          (!!x.coordinatorID && x.coordinatorID == this.curUserID) ||
          (!!x.generalForemanID && x.generalForemanID == this.curUserID) ||
          (!!x.foremanID && x.foremanID == this.curUserID)
      );
      if (this.requestorFilterIsMine && myWorkOrders.length == 0) {
        this.requestorFilterIsMine = false;
      } else if (!this.requestorFilterIsMine && myWorkOrders.length > 0) {
        this.requestorFilterIsMine = true;
      }
    } catch (error) {
      if ((error as any).statusCode == 403) {
        this.inlineMessage.message = "";
      } else {
        this.handleError(error as Error);
      }
    } finally {
      this.processing = false;
    }
  }
});

